import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Routes } from "../data/routes"
import { ClosingCTA } from "../components/CTA"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Content = {
  ctaHeader: "Learn how you can bring 24/7 math tutoring to your school",
  ctaLink: Routes.schools,
  ctaText: "Schedule demo",

  // Closing CTA
  closingCTA: "Learn how you can bring 24/7 math tutoring to your school",
  closingCTAActions: [
    {
      link: Routes.schools,
      text: "Schedule demo",
    },
  ],
}

const MediaResourceTemplate = ({ pageContext, data, location }) => {
  const post = data.markdownRemark
  const siteTitle =
    data.site.siteMetadata?.title || `${post.frontmatter.date} blog post`
  const { previous, next } = pageContext
  var related = []
  if (previous) {
    related.push(previous)
  }
  if (next) {
    related.push(next)
  }
  const image = getImage(post.frontmatter.coverImage)
  const imageAltText =
    post.frontmatter.coverImageAltText || post.frontmatter.title
  const categoryLabel = post.frontmatter.categories[0]?.replace(/-/g, " ") ?? ""
  const description = post.frontmatter.description || post.excerpt

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={description}
        image={post.frontmatter.coverImage.publicURL}
        route={`${Routes.blog}${post.frontmatter.slug}`}
      />
      <div className="relative overflow-hidden">
        <div className="relative">
          <div className="absolute inset-0">
            <GatsbyImage
              className="w-full h-full object-cover"
              image={image}
              alt={imageAltText}
            />
            <div className="absolute inset-0 bg-gray-600 mix-blend-multiply" />
          </div>
          <div className="relative max-w-2xl mx-auto py-24 px-4 sm:pt-60 sm:pb-8 sm:px-6 lg:px-8">
            <h1>
              <span className="block text-lg text-center text-primary font-bold tracking-wide uppercase">
                {categoryLabel}
              </span>
              <span className="mt-2 block text-3xl text-center leading-8 font-black tracking-tight text-white sm:text-4xl">
                {post.frontmatter.title}
              </span>
            </h1>
          </div>
        </div>
        <div className="relative px-4 mb-16 sm:px-6 lg:px-8 max-w-2xl mx-auto ">
          <div className="mt-6 text-gray-500 mx-auto">
            <p className="mb-4">{post.frontmatter.date}</p>
            <article
              className="blog-post prose"
              itemScope
              itemType="http://schema.org/Article"
            >
              <section
                dangerouslySetInnerHTML={{ __html: post.html }}
                itemProp="articleBody"
              />
            </article>
          </div>
        </div>
      </div>
      <ClosingCTA
        title={Content.closingCTA}
        actions={Content.closingCTAActions}
      />
    </Layout>
  )
}

export default MediaResourceTemplate

export const pageQuery = graphql`
  query MediaResourceBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        description
        categories
        slug
        coverImage {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 800)
          }
        }
      }
    }
  }
`
